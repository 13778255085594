<template>
  <a-drawer
      title="新增用户"
      :maskClosable="false"
      width=650
      placement="right"
      :closable="false"
      @close="onClose"
      :visible="visible"
      style="height: calc(100% - 55px);overflow: auto;padding-bottom: 53px;">
    <a-form :form="form">
      <a-form-item label='用户名'
                   v-bind="formItemLayout"
                   :validateStatus="validateStatus"
                   :help="help">
        <a-input @blur="handleUserNameBlur"
                 v-decorator="['username',{rules: [{ required: true, message: '用户名不能为空'}]}]"/>
      </a-form-item>
      <a-form-item label='密码' v-bind="formItemLayout">
        <a-tooltip title='新用户默认密码为 1234qwer'>
          <a-input type='password' readOnly
                   v-decorator="['password',{rules: [{ required: true, message: '密码不能为空'}],
                   initialValue:defaultPassword}]"/>
        </a-tooltip>
      </a-form-item>
      <a-form-item label="手机" v-bind="formItemLayout">
        <a-input
            v-decorator="['mobile', {rules: [
            { pattern: '^0?(1[0-9])[0-9]{9}$', message: '请输入正确的手机号'}
          ]}]"/>
      </a-form-item>
      <a-form-item label='角色' v-bind="formItemLayout">
        <a-select
            mode="multiple"
            :allowClear="true"
            style="width: 100%"
            v-decorator="['role',{rules: [{ required: true, message: '请选择角色' }]}]">
          <a-select-option v-for="r in roleData" :key="r.roleId">{{ r.roleName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label='部门' v-bind="formItemLayout">
        <a-tree-select
            :allowClear="true"
            :dropdownStyle="{ maxHeight: '220px', overflow: 'auto' }"
            :treeData="deptTreeData"
            v-decorator="['deptId']">
        </a-tree-select>
      </a-form-item>
      <a-form-item label='状态' v-bind="formItemLayout">
        <a-radio-group
            v-decorator="['status',{rules: [{ required: true, message: '请选择状态'}],initialValue:1}]">
          <a-radio :value="0">锁定</a-radio>
          <a-radio :value="1">有效</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
    <div class="drawer-bootom-button">
      <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交</a-button>
    </div>
  </a-drawer>
</template>
<script>

const formItemLayout = {
  labelCol: {span: 3},
  wrapperCol: {span: 18}
}
export default {
  name: 'UserAdd',
  props: {
    visible: {
      default: false
    }
  },
  data () {
    return {
      loading: false,
      roleData: [],
      deptTreeData: [],
      formItemLayout,
      defaultPassword: '1234qwer',
      form: this.$form.createForm(this),
      validateStatus: '',
      searchSaler: {},
      help: ''
    }
  },
  methods: {
    reset () {
      this.validateStatus = ''
      this.help = ''
      this.loading = false
      this.searchSaler = {}
      this.form.resetFields()
    },
    onClose () {
      this.reset()
      this.$emit('close')
    },
    handleSalerChange (value) {
      this.searchSaler = value
      this.form.setFieldsValue({'systemId': value.key})
    },
    handleSubmit () {
      if (this.validateStatus !== 'success') {
        this.handleUserNameBlur()
      }
      this.form.validateFields((err, values) => {
        if (!err && this.validateStatus === 'success') {
          this.loading = true
          values.roleId = values.role.join(',')
          values.systemId = this.searchSaler.key

          delete values.role
          this.$postJson('user/save', {
            ...values
          }).then((r) => {
            this.reset()
            this.$emit('success')
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    handleUserNameBlur () {
      let username = this.form.getFieldValue('username')
      if (username) {
        if (username.length > 15) {
          this.validateStatus = 'error'
          this.help = '用户名不能超过15个字符'
        } else if (username.length < 4) {
          this.validateStatus = 'error'
          this.help = '用户名不能少于4个字符'
        } else {
          this.validateStatus = 'validating'
          this.$get(`user/check/${username}`).then((r) => {
            if (r.data) {
              this.validateStatus = 'success'
              this.help = ''
            } else {
              this.validateStatus = 'error'
              this.help = '抱歉，该用户名已存在'
            }
          })
        }
      } else {
        this.validateStatus = 'error'
        this.help = '用户名不能为空'
      }
    }
  },
  watch: {
    visible () {
      if (this.visible) {
        this.$get('role').then((r) => {
          this.roleData = r.data.records
        })
        this.$get('dept').then((r) => {
          this.deptTreeData = r.data.children
        })
      }
    }
  }
}
</script>
